import React from 'react';

import './Gallery.scss';
import { Helmet } from 'react-helmet';

export default function Gallery() {
    return (
        <div className="gallery">
            <Helmet>
                <title>Gallery - Half Seed</title>
            </Helmet>
            <h3>Gallery Coming Soon</h3>
        </div>
    )
}