import React, { useContext, useState, useEffect } from 'react'
import { ThemeContext } from './context/ThemeContext.tsx'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ScrollToTop from './util/ScrollToTop.ts'

import Header from './components/Header/Header.tsx'
import Footer from './components/Footer/Footer.tsx'
import Sidebar from './components/Sidebar/Sidebar.tsx'

import Home from './pages/Home/Home.tsx'
import Gallery from './pages/Gallery/Gallery.tsx'
import Blog from './pages/Blog/Blog.tsx'
import Privacy from './pages/Privacy/Privacy.tsx'
import Terms from './pages/Terms/Terms.tsx'
import PageNotFound from './pages/PageNotFound/PageNotFound.tsx'

import './App.scss'

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};


function App() {
  const { darkTheme } = useContext(ThemeContext);

  return (
    <div className={`App ${darkTheme ? 'theme-dark' : 'theme-light'}`}>
      <Router>
        <ScrollToTop />
        <Header />
        <Sidebar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
