import React, { ReactElement } from 'react';

import Tag from '../Tag/Tag.tsx';
import Author, { AuthorProps } from '../Author/Author.tsx';

import './BlogPost.scss';
interface BlogProps {
    title: string;
    date: string;
    readTime: string;
    authors: AuthorProps[];
    content: ReactElement<any, any>;
    tags: string[];
}

const BlogPost: React.FC<BlogProps> = ({ title, tags, authors, date, readTime, content: Content }) => {
    return (
        <div className="post">
            <h1 className="title">{title}</h1>

            <div className="meta">
                <span>{date}</span> · <span>{readTime}</span>
            </div>

            <div className="authors">
                {authors.map((author, index) => (
                    <Author key={index} {...author} />
                ))}
            </div>

            {Content}

            <div className="tags">
                Tags: {tags.map((tag, index) => (
                    <Tag key={index} name={tag} />
                ))}
            </div>
        </div>
    );
};

export default BlogPost;
