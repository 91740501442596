import React from 'react'
import { FaChevronCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import './Hero.scss'

export default function Hero() {
    return (
        <div className="hero">
                <h1 className='title'>Half Seed</h1>
                <span className='blast'>Software • Digital Art • Innovation</span>
                <h2 className='subtitle'>"From the Seed, to the Root, to the Tree, to the Fruit"</h2>
                <Link to="/gallery">
                    <button className='primary-button'>
                        View App Gallery
                        <FaChevronCircleRight />
                    </button>
                </Link>
        </div>
    )
}