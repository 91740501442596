import React from 'react';
import { Helmet } from 'react-helmet';
import BlogPost from '../../components/BlogPost/BlogPost.tsx';

import logo from '../../assets/images/logo.png';

import './Blog.scss';



export default function Blog() {

    return (
        <div className="blog">
            <Helmet>
                <title>Blog - Half Seed</title>
            </Helmet>
            <BlogPost
                title="Introduction"
                date="June 20, 2024"
                readTime="One min read"
                authors={[{name: 'Johnathan Hill', title: 'Software Engineer', link: 'https://johnbhill.com', image: 'https://media.licdn.com/dms/image/D5603AQFH1iVV-2hI4Q/profile-displayphoto-shrink_800_800/0/1689988499402?e=1726099200&v=beta&t=UpYXXE9DA_N5UqAUxayJdcht4j26fhLF_Rk8cX0HAD8'}]} 
                tags={['Welcome', 'Hello', 'Half Seed']}
                content={
                <div>
                    <h2>Welcome to Half Seed!</h2>
                    <p>
                        Hi there! Here you can read about some of the exciting things we're working on and maybe find some inspiration for your next project. We're a team of developers who are working on exciting projects in different fields of web development and design.
                    </p>

                    <img src={logo} style={{width: '10rem', borderRadius: '10rem'}} alt="logo" />
                </div>
            }
            />
        </div>
    );
}
