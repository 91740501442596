import React, { createContext, useContext, useState } from 'react'

const PreferencesContext = createContext({
    isMenuOpen: false,
    toggleMenu: () => {}
});

const usePreferences = () => useContext(PreferencesContext);

const PreferencesProvider = ({ children }: { children: React.ReactNode }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <PreferencesContext.Provider value={{ isMenuOpen, toggleMenu }}>
            {children}
        </PreferencesContext.Provider>
    )
}

export { PreferencesProvider,  usePreferences, PreferencesContext };

