import React from 'react'
import { FaBars } from 'react-icons/fa'
import ThemeToggle from '../ThemeToggle/ThemeToggle.tsx'
import { usePreferences } from '../../context/PreferencesContext.tsx'
import { Link } from 'react-router-dom'

import logo from '../../assets/images/logo.png'
import './Header.scss'

export default function Header() {
  const preferences = usePreferences();

  function toggleMenu() {
    console.log('clicked')
    preferences.toggleMenu();
  }

  return (
    <header>
      <div className='left-section'>
        <div className='logo-section'>
          <button className='menu-button' onClick={() => toggleMenu()}>
            <FaBars className='menu-icon' />
          </button>

          <Link to="/">
            <img src={logo} alt="logo" width="100px" />
          </Link>
          <Link to="/"><h1>Half Seed</h1></Link>
        </div>

        <nav>
          <ul>
            <li><Link to='/gallery'>Gallery</Link></li>
            <li><Link to='/blog'>Blog</Link></li>
          </ul>
        </nav>
      </div>

      <div className='right-section'>
        <ThemeToggle />
      </div>
    </header>
  )
}