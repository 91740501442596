import React, { createContext, useEffect, useState } from 'react';

const darkThemeColor = '#252525';
const lightThemeColor = '#ffffff';

const ThemeContext = createContext({
    darkTheme: false,
    toggleTheme: () => { }
});

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
    const [darkTheme, setDarkTheme] = useState(true);

    useEffect(() => {
        const storedTheme = localStorage.getItem('darkTheme');
        if (storedTheme) {
            setDarkTheme(JSON.parse(storedTheme));
        }
    }, []);

    const toggleTheme = () => {
        setDarkTheme((prevTheme) => {
            const newTheme = !prevTheme;
            localStorage.setItem('darkTheme', JSON.stringify(newTheme));
            return newTheme;
        });
    };

    const updateThemeColorMetaTag = (isDarkTheme: boolean) => {
        const metaTag = document.querySelector('meta[name="theme-color"]');
        if (metaTag) {
            metaTag.setAttribute('content', isDarkTheme ? darkThemeColor : lightThemeColor);
        } else {
            const newMetaTag = document.createElement('meta');
            newMetaTag.setAttribute('name', 'theme-color');
            newMetaTag.setAttribute('content', isDarkTheme ? darkThemeColor : lightThemeColor);
            document.head.appendChild(newMetaTag);
        }
    };

    useEffect(() => {
        updateThemeColorMetaTag(darkTheme);
    }, [darkTheme]);

    return (
        <ThemeContext.Provider value={{ darkTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeProvider, ThemeContext };
