import React from 'react';
import { FaTimes } from 'react-icons/fa';

import './Sidebar.scss';
import logo from '../../assets/images/logo.png';
import { usePreferences } from '../../context/PreferencesContext.tsx';
import { Link } from 'react-router-dom';

export default function Sidebar() {
    const preferences = usePreferences();

    function toggleMenu() {
        preferences.toggleMenu();
    }

    return (
        <div className={`sidebar-wrapper ${preferences.isMenuOpen ? 'open' : ''}`}>
            <div className='sidebar-underlay' onClick={() => toggleMenu()}></div>
            <div className="sidebar">
                <header>
                    <div className='logo-section'>
                        <img src={logo} alt='logo' width='100px' />
                        <Link to="/" onClick={toggleMenu}><h1>Half Seed</h1></Link>
                    </div>

                    <button className='close' onClick={toggleMenu}><FaTimes /></button>
                </header>

                <div className='menu-section'>
                    <nav>
                        <ul>
                            <Link to='/gallery' onClick={toggleMenu}><li>Gallery</li></Link>
                            <Link to='/blog' onClick={toggleMenu}><li>Blog</li></Link>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}
