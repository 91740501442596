import React, { useContext } from "react";
import { ThemeContext } from '../../context/ThemeContext.tsx'
import { FaMoon, FaSun } from 'react-icons/fa'

import "./ThemeToggle.scss"

const ThemeToggle = () => {
    const { darkTheme, toggleTheme } = useContext(ThemeContext);

    return (
        <div className="theme-toggle">
            {darkTheme ? <FaMoon className="moon" /> : <FaSun className="sun" />}
            <label className="switch">
                <input
                    type="checkbox"
                    checked={darkTheme}
                    onChange={toggleTheme}
                    className="toggle-input"
                />
                <span className="slider round"></span>
            </label>
        </div>
    );
};

export default ThemeToggle;