import React from 'react';
import Hero from '../../components/Hero/Hero.tsx';
import './Home.scss'
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
        <div className="home">
            <Helmet>
                <title>Half Seed - Software Development Company in California</title>
            </Helmet>
            <Hero />
        </div>
    )
}