import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log('ScrollToTop useEffect triggered for pathname:', pathname);
    const scrollingElement = document.querySelector('.App');
    if (scrollingElement) {
      scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
      console.log('Scrolled to top of .App');
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;