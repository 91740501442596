import React from 'react';
import './Tag.scss';

interface TagProps {
    name: string;
}

const Tag: React.FC<TagProps> = ({ name }) => {
    return <span className="tag">{name}</span>;
};

export default Tag;
