import React from 'react'
import './Footer.scss'

import  { FaGithub, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Footer() {
    function copyRightYear() {
        const currentYear = new Date().getFullYear();
        return currentYear;
    }

    return (
        <footer>
            <div className="container">
            <section>
                <h2>Privacy and Terms</h2>
                <ul>
                    <li><Link to="/privacy">Privacy Policy</Link></li>
                    <li><Link to="/terms">Terms & Conditions</Link></li>
                </ul>
            </section>
            <section>
                <h2>Community</h2>
                <div className="social-links">
                    <Link to="https://github.com/halfseedllc" className="github" target="_blank" rel="noreferrer"><FaGithub /></Link>
                    <Link to="https://www.instagram.com/halfseed/" className="instagram" target="_blank" rel="noreferrer"><FaInstagram /></Link>
                    <Link to="https://www.linkedin.com/company/halfseed" className="linkedin" target="_blank" rel="noreferrer"><FaLinkedin /></Link>
                    <Link to="https://twitter.com/halfseedllc" className="twitter" target="_blank" rel="noreferrer"><FaTwitter /></Link>
                </div>
            </section>
            <section>
                <h2>Contact & Support</h2>
                <ul>
                    <li><Link to="mailto:feedback@halfseed.com">contact@halfseed.com</Link></li>
                    <li><Link to="mailto:support@halfseed.com">support@halfseed.com</Link></li>
                </ul>
            </section>
            </div>
            <p>Copyright © {copyRightYear()} Half Seed, LLC. All rights reserved.</p>
        </footer>
    )
}