import React from 'react'
import { Helmet } from 'react-helmet'

export default function PageNotFound() {
    return (
        <div className="page-not-found">
            <Helmet>
                <title>Page Not Found - Half Seed</title>
            </Helmet>
            <h1>404</h1>
            <h1>Page Not Found</h1>
        </div>
    )
}