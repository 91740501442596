import React from 'react';
import './Author.scss';
import { Link } from 'react-router-dom';

export interface AuthorProps {
    name: string;
    image?: string;
    title?: string;
    link?: string;
}

const Author: React.FC<AuthorProps> = (props: AuthorProps) => {
    return (
        <div className="author">
            {props.image && <img src={props.image} alt={props.name} className="image" />}
            <div className="meta">
                <Link to={props.link ?? '/blog'} className="name">{props.name}</Link>
                {props.title && <span className="title">{props.title}</span>}
            </div>
        </div>
    );
};

export default Author;
